/**
 * VideoEmbed
 *
 * @selector [data-js="VideoEmbed"]
 * @enabled true
 */
import { base } from 'app/util/base';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
};

const VIDEO_REGEX =
	/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/;

export default function VideoEmbed() {
	// Private vars
	const instance = {};
	let url, video, player, button;

	const parseVideo = (url) => {
		// - Supported YouTube URL formats:
		//   - http://www.youtube.com/watch?v=My2FRPA3Gf8
		//   - http://youtu.be/My2FRPA3Gf8
		//   - https://youtube.googleapis.com/v/My2FRPA3Gf8
		// - Supported Vimeo URL formats:
		//   - http://vimeo.com/25451551
		//   - http://player.vimeo.com/video/25451551
		// - Also supports relative URLs:
		//   - //player.vimeo.com/video/25451551

		let type;

		url.match(VIDEO_REGEX);

		if (RegExp.$3.indexOf('youtu') > -1) {
			type = 'youtube';
		} else if (RegExp.$3.indexOf('vimeo') > -1) {
			type = 'vimeo';
		}

		return {
			type: type,
			id: RegExp.$6,
		};
	};

	const handleClick = (evt) => {
		evt.preventDefault();
		evt.stopPropagation();
		unbindEvents();
		instance.el.classList.add('playing');
		import(/* webpackChunkName: "YOUTUBE-PLAYER" */ 'youtube-player').then(
			initialize
		);
	};

	const bindEvents = () => {
		button.addEventListener('click', handleClick);
	};

	const unbindEvents = () => {
		button.removeEventListener('click', handleClick);
	};

	const initialize = (YouTubePlayer) => {
		player = YouTubePlayer.default(video.id, {
			videoId: video.id,
			playerVars: {
				rel: 0,
				showinfo: 0,
				modestbranding: 1,
			},
		});
		player.playVideo();
	};

	// Public vars

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		Object.assign({}, defaults, options);

		button = instance.ref('button');
		url = button.getAttribute('href');
		video = parseVideo(url);

		instance.ref('wrapper').setAttribute('id', video.id);

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
