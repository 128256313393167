/**
 * Filter
 *
 * @selector [data-js="Filter"]
 * @enabled true
 */
import { base } from 'app/util/base';

const defaults = {
	// cookieExpiration: 0,
	// cookieId: null,
	// autoHideTimer: 0,
};

const config = {
	optionsAttr: 'data-options',
};

export default function Filter() {
	// Private vars
	let abortController = null;
	let signal = null;
	const instance = {};
	let settings = {};
	let filterContainer = null;
	let filterOpenPanelTrigger = null;
	let filterPanelBackground = null;
	let filterPanelClose = null;
	let filterPanelSearchId = null;
	let filterSelectedContainer = null;
	let filterSelectedItemsCounter = null;
	let resultsSection = null;
	let resultsContainer = null;
	let resultsHitsContainerWrapper = null;
	let resultsHitsContainer = null;
	let filterCheckboxes = null;
	let resetButton = null;
	let expanders = null;
	let paginationContainer = null;
	let paginationOverviewText = null;
	let paginationButtonPrev = null;
	let paginationButtonNext = null;
	let paginationLinks = [];

	// Private methods
	const bindEvents = () => {
		filterCheckboxes.forEach((checkbox) => {
			if (checkbox.value === 'all') {
				checkbox.addEventListener('change', handleFilterParentCheckboxChange);
			} else {
				checkbox.addEventListener('change', handleFilterCheckboxChange);
			}
		});

		resetButton.addEventListener('click', handleFilterReset);

		expanders.forEach((expander) => {
			expander.addEventListener('click', handleExpanderClick);
		});

		filterOpenPanelTrigger.addEventListener('click', handlePanelOpen);

		filterPanelClose.addEventListener('click', handlePanelClose);

		filterPanelSearchId.addEventListener('click', handlePanelClose);

		filterPanelBackground.addEventListener('click', handlePanelClose);

		bindPaginationEvents();

		bindTagsEvents();
	};

	const unbindEvents = () => {
		filterCheckboxes.forEach((checkbox) => {
			if (checkbox.value === 'all') {
				checkbox.removeEventListener(
					'change',
					handleFilterParentCheckboxChange
				);
			} else {
				checkbox.removeEventListener('change', handleFilterCheckboxChange);
			}
		});

		resetButton.removeEventListener('click', handleFilterReset);

		expanders.forEach((expander) => {
			expander.removeEventListener('click', handleExpanderClick);
		});

		unbindPaginationEvents();

		unbindTagsEvents();
	};

	const bindPaginationEvents = () => {
		paginationButtonPrev?.addEventListener('click', handlePaginationClick);
		paginationButtonNext?.addEventListener('click', handlePaginationClick);
		paginationLinks?.forEach((link) => {
			link.addEventListener('click', handlePaginationClick);
		});
	};

	const unbindPaginationEvents = () => {
		paginationButtonPrev?.removeEventListener('click', handlePaginationClick);
		paginationButtonNext?.removeEventListener('click', handlePaginationClick);
		paginationLinks?.forEach((link) => {
			link.removeEventListener('click', handlePaginationClick);
		});
	};

	const updatePagination = () => {
		unbindPaginationEvents();
		paginationContainer = instance.el.querySelector(
			`#${settings.paginationContainerId}`
		);
		paginationButtonPrev = instance.el.querySelector(
			`.${settings.paginationButtonPrevClass}`
		);
		paginationButtonNext = instance.el.querySelector(
			`.${settings.paginationButtonNextClass}`
		);
		paginationLinks = Array.from(
			instance.el.querySelectorAll(`.${settings.paginationButtonLinkClass}`)
		);
		bindPaginationEvents();
	};

	const bindTagsEvents = () => {
		const tags = Array.from(
			filterSelectedContainer.querySelectorAll(
				`.${settings.filterSelectedTagClass}`
			)
		);
		tags?.forEach((tag) => {
			tag.addEventListener('click', handleTagRemove);
		});
	};

	const unbindTagsEvents = () => {
		const tags = Array.from(
			filterSelectedContainer.querySelectorAll(
				`.${settings.filterSelectedTagClass}`
			)
		);
		tags?.forEach((tag) => {
			tag.removeEventListener('click', handleTagRemove);
		});
	};

	const handleFilterParentCheckboxChange = (event) => {
		updateChildCheckboxes(event.target.name, event.target.checked);
		updateUrlOnFilterChange(event.target.name, event.target.checked, true);
		updateTags();
		updateFilterCounter();
		requestFilterResults();
	};

	const handleFilterCheckboxChange = (event) => {
		updateParentCheckboxes(event.target.name);
		updateUrlOnFilterChange(event.target.name, event.target.checked, false);
		updateTags();
		updateFilterCounter();
		requestFilterResults();
	};

	const handleFilterReset = () => {
		resetCheckboxes();
		resetTags();
		updateFilterCounter();
		resetUrl();
		requestFilterResults();
	};

	const handleExpanderClick = (event) => {
		event.preventDefault();
		const trigger = event.currentTarget;
		const panel = filterContainer.querySelector(
			`#${trigger.getAttribute('aria-controls')}`
		);
		const isExpanded = trigger.getAttribute('aria-expanded') === 'true';
		if (isExpanded) {
			trigger.setAttribute('aria-expanded', 'false');
			panel.setAttribute('aria-hidden', 'true');
		} else {
			trigger.setAttribute('aria-expanded', 'true');
			panel.setAttribute('aria-hidden', 'false');
		}
	};

	const handlePanelOpen = (event) => {
		event.preventDefault();
		instance.el.classList.add(settings.filterPanelOpenClass);
	};

	const handlePanelClose = (event) => {
		event.preventDefault();
		instance.el.classList.remove(settings.filterPanelOpenClass);
	};

	const handleTagRemove = (event) => {
		event.preventDefault();
		const tag = event.currentTarget;
		const filterName = tag.getAttribute('data-filter-name');
		const filterValue = tag.getAttribute('data-filter-value');
		const isParentCheckbox = filterValue === 'all';

		// Adjust checked state of corresponding checkbox.
		const checkbox = filterContainer.querySelector(
			`input[type=checkbox][name="${filterName}"][value="${filterValue}"]`
		);
		checkbox.checked = false;

		// Update parent or child checkboxes.
		if (isParentCheckbox) {
			updateChildCheckboxes(filterName, false);
		} else {
			updateParentCheckboxes(filterName);
		}

		// Update URL and request new results.
		updateUrlOnFilterChange(filterName, false, isParentCheckbox);
		updateFilterCounter();
		updateTags();
		requestFilterResults();
	};

	const updateTags = () => {
		resetTags();
		// Timeout needed to ensure that the checkboxes have been updated.
		setTimeout(() => {
			const checkedCheckboxes = filterContainer.querySelectorAll(
				`input[type=checkbox]:checked`
			);
			checkedCheckboxes.forEach((checkbox) => {
				filterSelectedContainer.appendChild(
					createTag(
						checkbox.nextElementSibling.textContent,
						checkbox.name,
						checkbox.value
					)
				);
			});
			bindTagsEvents();
		}, 0);
	};

	const resetTags = () => {
		unbindTagsEvents();
		filterSelectedContainer.innerHTML = '';
	};

	const createTag = (title, name, value) => {
		const tag = document.createElement('button');
		tag.classList.add(settings.filterSelectedTagClass);
		tag.setAttribute('data-filter-name', name);
		tag.setAttribute('data-filter-value', value);
		tag.textContent = title;
		return tag;
	};

	const updateFilterCounter = () => {
		const filterCount = filterContainer.querySelectorAll(
			`input[type=checkbox]:checked`
		).length;
		filterSelectedItemsCounter.textContent = filterCount > 0 ? filterCount : '';
	};

	const handlePaginationClick = (event) => {
		event.preventDefault();
		window.history.pushState({}, '', decodeURI(new URL(event.target.href)));
		requestFilterResults();
	};

	const updateChildCheckboxes = (name, isChecked) => {
		const childCheckboxes = filterContainer.querySelectorAll(
			`input[type=checkbox][name^="${name}"]:not([value="all"])`
		);
		childCheckboxes.forEach((checkbox) => {
			checkbox.checked = isChecked;
		});
	};

	const updateParentCheckboxes = (name) => {
		const parentCheckbox = filterContainer.querySelector(
			`input[type=checkbox][name^="${name}"][value="all"]`
		);
		if (!parentCheckbox) {
			return;
		}

		parentCheckbox.checked = false;
		parentCheckbox.indeterminate = false;

		const childCheckboxes = filterContainer.querySelectorAll(
			`input[type=checkbox][name^="${name}"]:not([value="all"])`
		);
		const areAllChildrenChecked = Array.from(childCheckboxes).every(
			(checkbox) => checkbox.checked
		);

		if (areAllChildrenChecked) {
			parentCheckbox.checked = areAllChildrenChecked;
			return;
		}

		const areSomeChildrenChecked = Array.from(childCheckboxes).some(
			(checkbox) => checkbox.checked
		);

		if (areSomeChildrenChecked) {
			parentCheckbox.indeterminate = true;
		}
	};

	const updateCheckboxesAfterRequest = (filterData) => {
		const checkboxes = Array.from(
			filterContainer.querySelectorAll(`input[type=checkbox]`)
		);

		filterData.forEach((filterGroup) => {
			filterGroup.filter?.forEach((filterTopLevel) => {
				const checkbox = checkboxes.find(
					(checkbox) =>
						checkbox.name === filterTopLevel.name &&
						checkbox.value === filterTopLevel.value
				);
				if (checkbox) {
					checkbox.disabled = filterTopLevel.disabled;
				}
				filterTopLevel.children?.forEach((filterSecondLevel) => {
					const childCheckbox = checkboxes.find(
						(checkbox) =>
							checkbox.name === filterSecondLevel.name &&
							checkbox.value === filterSecondLevel.value
					);
					if (childCheckbox) {
						childCheckbox.disabled = filterSecondLevel.disabled;
					}
					filterSecondLevel.children?.forEach((filterThirdLevel) => {
						const grandChildCheckbox = checkboxes.find(
							(checkbox) =>
								checkbox.name === filterThirdLevel.name &&
								checkbox.value === filterThirdLevel.value
						);
						if (grandChildCheckbox) {
							grandChildCheckbox.disabled = filterThirdLevel.disabled;
						}
					});
				});
			});
		});

		updateParentCheckboxesBasedOnHtml();
	};

	const updateParentCheckboxesBasedOnHtml = () => {
		const parentCheckboxes = filterContainer.querySelectorAll(
			`input[type=checkbox][value="all"]`
		);

		parentCheckboxes.forEach((parentCheckbox) => {
			const childCheckboxes = filterContainer.querySelectorAll(
				`input[type=checkbox][name="${parentCheckbox.name}"]:not([value="all"])`
			);
			const childCheckboxesChecked = Array.from(childCheckboxes).filter(
				(checkbox) => checkbox.checked
			);
			if (childCheckboxes?.length === childCheckboxesChecked?.length) {
				parentCheckbox.checked = true;
				parentCheckbox.indeterminate = false;
			} else if (childCheckboxesChecked?.length > 0) {
				parentCheckbox.checked = false;
				parentCheckbox.indeterminate = true;
			} else {
				parentCheckbox.checked = false;
				parentCheckbox.indeterminate = false;
			}
		});
	};

	const resetCheckboxes = () => {
		const checkboxes = Array.from(
			filterContainer.querySelectorAll(`input[type=checkbox]`)
		);
		checkboxes.forEach((checkbox) => {
			checkbox.checked = false;
			checkbox.disabled = false;
		});
	};

	const updateUrlOnFilterChange = (name, isChecked, isParentCheckbox) => {
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search.slice(1));
		let filterArray = [];
		const parentCheckbox = filterContainer.querySelector(
			`input[type=checkbox][name="${name}"][value="all"]`
		);

		if (
			(isParentCheckbox && isChecked) ||
			(parentCheckbox && parentCheckbox.checked)
		) {
			filterArray = ['all'];
		} else if (isParentCheckbox && !isChecked) {
			filterArray = [];
		} else {
			// Iterate over checkboxes and determine the checked ones.
			const childCheckboxes = filterContainer.querySelectorAll(
				`input[type=checkbox][name^="${name}"]:not([value="all"])`
			);
			childCheckboxes.forEach((checkbox) => {
				if (checkbox.checked) {
					filterArray.push(checkbox.value);
				}
			});

			// If all checkboxes are checked, remove all values from the array and add 'all' instead.
			if (childCheckboxes.length === filterArray.length) {
				filterArray = ['all'];
			}
		}

		// Remove offset from params if it exists.
		params.delete('offset');

		// Delete params with this name from the URL if it exists.
		params.delete(name);

		// Add the new params for this name to the URL.
		filterArray.forEach((item) => {
			params.append(name, item);
		});

		// Update the URL.
		url.search = params.toString();
		window.history.pushState({}, '', decodeURI(url));
	};

	const resetUrl = () => {
		const url = new URL(window.location.href);
		url.search = '';
		window.history.pushState({}, '', decodeURI(url));
	};

	const requestFilterResults = () => {
		if (abortController) {
			abortController.abort();
		}

		abortController = new AbortController();
		signal = abortController.signal;

		const currentUrl = new URL(window.location.href);
		const paramString = currentUrl.search.slice(1);
		const apiUrl = `${settings.endpoint}?${paramString}`.replace('/?', '?');

		instance.el.classList.add(settings.resultsLoadingClass);
		return fetch(apiUrl, {
			method: 'GET',
			signal: signal, // signal is an AbortSignal object (see AbortController above)
			headers: {
				Accept: 'application/json',
			},
		})
			.then((response) => response.json())
			.then((result) => processResult(result));
	};

	const processResult = (result) => {
		if (
			!resultsContainer ||
			!resultsHitsContainerWrapper ||
			!resultsHitsContainer ||
			!paginationContainer ||
			!paginationOverviewText
		) {
			return;
		}

		// Print results
		resultsContainer.innerHTML = result.html ?? '';
		resultsHitsContainerWrapper.hidden = result.resultsTotal === 0;
		resultsHitsContainer.innerHTML = result.resultsTotal ?? '';

		// Update pagination
		paginationContainer.innerHTML = result.pagination ?? '';
		paginationOverviewText.innerHTML = result.paginationOverviewText ?? '';

		// Rebind pagination events.
		updatePagination();

		// Update checkboxes
		updateCheckboxesAfterRequest(result.filterData);

		// Update tags
		updateTags();

		// Update filter counter
		updateFilterCounter();

		// Remove loading class
		instance.el.classList.remove(settings.resultsLoadingClass);

		// Reset abort controller
		abortController = null;
		resultsSection.scrollIntoView({ behavior: 'smooth' });
	};

	// Public methods
	instance.init = (element) => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		// Merge options with defaults
		settings = Object.assign({}, defaults, options);

		// Get DOM elements
		filterContainer = instance.el.querySelector(
			`#${settings.filterContainerId}`
		);
		filterOpenPanelTrigger = instance.el.querySelector(
			`#${settings.filterOpenPanelTriggerId}`
		);
		filterPanelBackground = instance.el.querySelector(
			`#${settings.filterPanelBackgroundId}`
		);
		filterPanelClose = instance.el.querySelector(
			`#${settings.filterPanelCloseId}`
		);
		filterPanelSearchId = instance.el.querySelector(
			`#${settings.filterPanelSearchId}`
		);
		filterSelectedContainer = instance.el.querySelector(
			`#${settings.filterSelectedContainerId}`
		);
		filterSelectedItemsCounter = instance.el.querySelector(
			`.${settings.filterSelectedItemsCounterClass}`
		);
		resultsSection = instance.el.querySelector(`#${settings.resultsSectionId}`);
		resultsContainer = instance.el.querySelector(
			`#${settings.resultsContainerId}`
		);
		resultsHitsContainerWrapper = instance.el.querySelector(
			`#${settings.resultsHitsContainerWrapperId}`
		);
		resultsHitsContainer = instance.el.querySelector(
			`#${settings.resultsHitsContainerId}`
		);
		filterCheckboxes = Array.from(
			filterContainer.querySelectorAll('input[type=checkbox][name^="filter"]')
		);
		resetButton = filterContainer.querySelector(`#${settings.filterResetId}`);
		expanders = Array.from(
			filterContainer.querySelectorAll(`.${settings.expanderButtonClass}`)
		);
		paginationContainer = instance.el.querySelector(
			`#${settings.paginationContainerId}`
		);
		paginationOverviewText = instance.el.querySelector(
			`#${settings.paginationOverviewTextId}`
		);
		paginationButtonPrev = instance.el.querySelector(
			`.${settings.paginationButtonPrevClass}`
		);
		paginationButtonNext = instance.el.querySelector(
			`.${settings.paginationButtonNextClass}`
		);
		paginationLinks = Array.from(
			instance.el.querySelectorAll(`.${settings.paginationButtonLinkClass}`)
		);

		bindEvents();

		updateParentCheckboxesBasedOnHtml();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
